

.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.toast-message {
  position: fixed;
  display: flex;
  align-items: center;
  left: 50%;
  width: calc(100% - 20px);
  box-sizing: border-box;
  max-width: 560px;
  padding: 20px;
  margin-bottom:24px;

  border-radius: 5px;
  background-color: #1E1E1E;
  transform: translateX(-50%);
  z-index: 1000;
  svg {
    margin-right: 10px;
  }
  p {
    color: var(--Color-White, #FFF);
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
  }
  .action-button {
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 8px;

    color: var(--Gray-Gray-FFF, #FFF);
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    text-transform: capitalize;
  }
}
@media screen and (min-width: 600px) {
  .toast-message {
    // width: auto;
    // min-width: 343px;
  }
}
