
::v-deep sup {position: relative; top:0.2em; vertical-align: top; font-size: 0.6em; font-weight: 400;}
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color .3s linear;
  z-index: 101;
  &.active {
    background-color: rgba(0,0,0,0.3);
  }
  z-index: 101;
}
.bottom-sheet-wrap {
  position: fixed !important;
  height: calc(var(--dvh) * 100); // fallback
  height: 100dvh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-sheet-container {
  position: absolute;
  transition: bottom .3s ease-out;
  bottom: -100%;
  &.active {
    bottom: 0;
  }
  z-index: 103;
  width: 100%;
  max-width: 600px;
  height: calc(calc(var(--dvh) * 100) - 45px); // fallback
  height: calc(100dvh - 45px);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #F8F7EF;
  padding: 16px 20px;
  box-sizing: border-box;
  .header {
    // background: red;
    display: flex;
    height:44px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &:has(.close-button.big) {
      justify-content: end;
      margin-bottom: 0;
    }
    .close-button {
      display: flex;
      width: 20px;
      height:20px;
      justify-content: center;
      align-items:center;
      &.big {
        display: flex;
        width: 24px;
        height: 24px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
    .button-slots {
      & > a, & > button {
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 0 4px;
        &.coral {
          color :#CF2027;
        }
        &.sky {
          color: #4C79D3;
        }
      }
    }
  }
  .bottom-sheet-body {
    box-sizing: border-box;
    // padding: 40px 20px;
    min-width: 310px;
    .bottom-sheet-title {
      color: #1E1E1E;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 24px;
    }
  }
}
