
input {
  caret-color: #F37324;
  position: relative;
  padding: 16px 11px;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  width:100%;
  height:50px;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  outline: none;
  background: #FFF;
  color: #1E1E1E;
  border-radius: 4px;
  &:disabled {
    // background-color: transparent;
    background-color: #ECEBE3 !important;
    -webkit-opacity: 1;
    -webkit-text-fill-color: #1E1E1E;
    opacity:1;
  }
  &::placeholder {
    color: #8F8F8F;
  }
}
input[type=number] {
  text-align: right;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
input[type=search] {
  /* width: calc(100% - 4px); */
}
input:focus {
  border-color: #1E1E1E;
  // animation: shadow 0.1s ease-in-out forwards;
  &.warning {
    border-color: #CF2027;
  }
  &.suceess {
    border-color: #32B478;
  }
}
// @keyframes shadow {
//   to {
//     box-shadow: 0 0 1px var(--theme-primary-color);
//     /* box-shadow: 0 0 6px red; */
//   }
// }
.hide-caret {
  caret-color: transparent !important;
}
