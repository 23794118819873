
.blind-control {
  width: 100%;
  &.disabled {
    touch-action: none;
    pointer-events: none;
  }
  .track {
    position: relative;
    display:flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 8px;
    padding: 0 13.5px;
    margin-bottom: 18px;
    border-radius: 4px;
    background:rgba(0, 0, 0, 0.10);
    cursor: pointer;
    touch-action: pan-y;
    .step {
      opacity: 0.1;
      background: #1E1E1E;
      width: 1px;
      &:first-of-type {
        opacity: 0;
      }
      &:last-of-type {
        opacity: 0;
      }
    }
    .knob {
      position:absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      margin-left: -13.5px;
      border-radius: 14px;
      background-color:#fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.10), 0px 0.5px 4px rgba(0, 0, 0, 0.12);
    }
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 300%;
        border-radius: 20px;
        // border: 1px dashed #ccc; /* for debug */
        // background-color: rgba(255,0,0,0.1); /* for debug */
    }
  }
  .labels {
    display:flex;
    justify-content: space-between;
    .label {
      width:27px;
      color: 787878;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}

