
* {
  font-family: 'Pretendard';
  font-size: 15px;
  letter-spacing: -0.2px;
  font-weight: 400;
  color: black;
  // scroll-behavior: smooth;
}
html {
  overscroll-behavior: contain;
}
html, body {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
.wrap-layout{
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 600px;
  // overflow: auto;
  &:has(.bg) {
    overflow: hidden;
  }
}
// PC ONLY
@media screen and (min-width: 769px) {

}
// MOBILE ONLY
@media screen and (max-width:768px) {

}

