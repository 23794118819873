@import url("https://unpkg.com/pretendard@1.3.9/dist/web/static/pretendard.css");
:root {
  --theme-primary-color: black;
  --theme-white: #ffffff;
  --theme-gray-10: #E9E9E9;
  --theme-gray-20: #E3E3E3;
  --theme-gray-30: #BCBCBC;
  --theme-gray-40: #A5A5A5;
  --theme-gray-50: #8F8F8F;
  --theme-gray-60: #787878;
  --theme-gray-70: #626262;
  --theme-gray-80: #4B4B4B;
  --theme-gray-90: #353535;
  --theme-gray-100: #1E1E1E;
  --theme-yellow-10: #F8F7EF;
  --theme-yellow-20: #ECEBE3;
  --theme-black: #000000;
  --diff: 0px;
}

/* reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
input,
select,
textarea,
button {
  vertical-align: middle;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
body {
  /* 뷰표트 변환시 폰트크기 자동확대 방지 */
  -webkit-text-size-adjust: none;
  /* 드래그 금지 */
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none
  // touch-action: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 14px;
  font-family: Pretendard, 'Apple SD Gothic Neo', sans-serif;
  color: #000;
}

a,
span,
p {
  font-family: Pretendard, 'Apple SD Gothic Neo', sans-serif;
}
input[type='text'],
input[type='password'],
input[type='submit'],
input[type='search'] {
  appearance: none;
}
input[type='checkbox'] {
  background-color: #666;
  appearance: checkbox;
}
button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
  border-radius: 0;
  appearance: button;
}
button {
  outline: none;
}
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
a {
  color: #242424;
  text-decoration: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}
em,
strong {
  font-weight: normal;
  font-style: normal;
}
small {
  font-size: initial;
}
iframe {
  border: 0 none;
}

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
