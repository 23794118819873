
::v-deep sup {position: relative; top:0.2em; vertical-align: top; font-size: 0.6em; font-weight: 400;}
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0,0,0,0.3);
  z-index: 101;
}
.tooltip-wrap {
  // background: #ff000099;
  position: fixed;
  height: calc(var(--dvh) * 100); // fallback
  height: 100dvh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-container {
  position: absolute;
  z-index: 103;
  min-width: 100px;
  width: fit-content;
  height: fit-content;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.15);
  min-width: 250px;
  .tooltip-body {
    .tooltip-title {
      // background: #ff000022;
      color: #1E1E1E;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      padding-bottom: 4px;
    }
    .description {
      // background: #0000ff22;
      color: #787878;
      font-size: 15px;
      line-height: 18px;
      height: 18px;
      font-style: normal;
      font-weight: 400;
      word-break: keep-all;
      white-space: pre-wrap;
    }
    .btn-close {
      float: right;
      display: flex;
      width: 20px;
      height: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
