
.context-menu {
  position: absolute;
  z-index: 100;
  margin-top: 4px;
  .bg {
    width: 100vw;
    height:calc(var(--dvh) * 100); // fallback
    height:100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }
  ul {
    min-width: 100px;
    border-radius: 12px;
    box-shadow: 0px 4px 72px 0px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(60px);
    li {
      background: rgba(255, 255, 255, 0.90);
      padding: 11px 16px 10px 16px;
      border-bottom: 1px solid  rgba(60, 60, 67, 0.20);

      color: var(--Label-Color-Light-Primary, #000);
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 129.412% */
      letter-spacing: -0.408px;
    }
    li.placeholder {
      color: var(--gray-50, #8F8F8F);
      font-size: 15px;
    }
    li:first-child {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    li:last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-bottom: 0;
    }
  }
}
