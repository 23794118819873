
.inputbox label {
  cursor: pointer;
}

input[type="checkbox"] {
  appearance: none;
  position: relative;
  border-radius: 999px;
  background: #F37324;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  width: 64px;
  height: 32px;
  padding: 8px 6px;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  background-color: #D0D0D0;
}

input[type="checkbox"]::before {
  content: "";
  width: 20px;
  height: 20px;
  // flex-shrink: 0;
  position: absolute;
  left: 6px;
  border-radius: 100%;
  background-color:#fff;
  transition: left 150ms ease-out;
}

input[type="checkbox"]:checked {
  background-color: #F37324;
}

input[type="checkbox"]:checked::before {
  background-color: #fff;
  left: 38px;
}

