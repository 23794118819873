
  .tab {
    box-sizing: border-box;
    .groups {
      position: sticky;
      top: 0px;
      z-index: 2;
      display: flex;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      overflow: visible;
      padding-bottom: 16px;
      // background: #F8F7EF;
      background-image: linear-gradient(
        to bottom,
        rgba(248, 247, 239 ,1),
        rgba(248, 247, 239 ,1),
        rgba(248, 247, 239 ,1),
        rgba(248, 247, 239, 0)
      );
      .tab-button {
        .tab-label {
          // background: #0000ff33;
          cursor: pointer;
          color: #1E1E1E;

          opacity: 0.2;
          font-family: Pretendard;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.4px;
          text-transform: capitalize;
        }
        .active {
          opacity: 1;
        }
      }
    }
    .tab-content{
      // background: #33333333;
      padding: 10px 0 34px 0;
      flex-grow: 1;
      min-height: 400px;
      box-sizing: border-box;
      overflow: auto;
    }
  }

// GALAXY FOLD OUT SCREEN
@media screen and (max-width:320px) {
  .tab-label {
    font-size: 19px !important;
  }
}
