
select {
    display: block;
    width: 100%;
    padding: 0 36px 0 0;
    line-height: 48px;
    height: 50px;
    text-indent: 14px;
    background-color: #FFF;
    border-color: #D1D5D9;
    border-radius: 4px;
    background: url('https://static.sparkplus.co/hompage/common/icon/icon_select_option.svg') top 10px right 12px no-repeat;
    appearance: none;
}
